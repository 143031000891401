import { Money } from "./Money";
import * as z from "zod";
import * as t from "io-ts";
import * as tt from "io-ts-types";
import { LoanModel, LoanModelC, Task } from "../Models";
import { MustSatisfy } from "./misc";
import config from "../config";
import { DecisionType } from "./API";
import { Role, RoleC } from "./roles";
import { LoanTypeC, LoanType } from "./LoanType";
import { ProjectInfoFormOutputs } from "../Forms/ProjectInfoForm";
import {
  EmailAddress,
  TaskId,
  Project as WireProject,
  ProjectId,
  ProjectFile,
  StoredFile,
  LinkedFile,
  InvoiceFile,
  User as APIUser,
  UserId,
} from "@project-centerline/project-centerline-api-types";
import { NonEmptyString } from "io-ts-types";
import { nullable } from "./util/typeUtils";

const frontendSettings = t.type({
  frontend: t.type({ useNewUI: tt.fromNullable(t.boolean, false) }),
});
const WireSettings = APIUser.types[1].props.settings;

export const UserSettings = t.partial({
  ...WireSettings.types[0].props,
  ...frontendSettings.props,
});

export const User = t.intersection([
  APIUser,
  nullable(t.partial({ settings: UserSettings })),
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type User = t.TypeOf<typeof User>;

declare class UserIdentityTag {
  private __kind: "UserIdentity";
}
// export type UserIdentity = string & UserIdentityTag;
export function isUserIdentity(
  value: string
): value is string & UserIdentityTag {
  return true;
}
export const UserIdentity = z.string().refine(isUserIdentity);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UserIdentity = z.infer<typeof UserIdentity>;

/**
 * Backend wire response to /getprojectitems with item: "Tasks"
 */
export interface BackendRawGetProjectItemsTasksResponse {
  tasks: BackendRawGetTaskResponse[];
  contractor: BackendUserResponse[];
}
/**
 * Cleaned up BackendRawGetProjectItemsTasksResponse with type conversions applied etc
 */
export interface BackendGetProjectItemsTasksResponse {
  tasks: Task[];
  contractor: BackendUserResponse[];
}

const emailSchema = z.string().email();
type legacyEmailAddress = z.infer<typeof emailSchema>;

interface ConciergePayment {
  amount: number;
  type: "john";
  status: "pending" | "paid";
}

interface PaymentNotRequired {
  type: "not-required";
  amount: 0;
}

interface StripeBase {
  type: "stripe";
  // paymentIntent: {
  //   id: string;
  //   client_secret: string | null;
  // };
  amount: number;
  status: "pending" | "paid";
  receiptUrl?: string;
}

interface StripePaymentLink extends StripeBase {
  paymentLink: {
    id: string;
    url: string;
  };
  invoice: never;
}

interface StripeInvoice extends StripeBase {
  invoice: {
    id: string;
    url: string;
    status: "open" | "paid";
  };
  paymentLink: never;
}

// TODO: put API definitions in their own npm package and share one source of truth frontend backend
interface BackendInspectionCommon {
  vendor: string;
  payment:
    | ConciergePayment
    | PaymentNotRequired
    | StripeInvoice
    | StripePaymentLink;
  id: string;
}

/**
 * Backend wire response to /getprojectitems with item: "Invoices"
 */
export interface BackendGetProjectItemsInvoicesRawResponseEntry {
  invoice_id: string;
  project_id: string;
  draws: Array<[string, number, ...string[]]>;
  timestamp: string;
  approval_status: ApprovalStatus;
  current_approver: EmailAddress;
  remaining_approvers: EmailAddress[] | null;
  created_by: EmailAddress;
  files: string[];
  final_approver: EmailAddress | null;
  is_put: boolean | null;
  ancestors: string[] | null;
  delegate: string | null;
  etag?: string;
  identifier: string;
  currentInspection?: BackendInspectionCommon;
  paid: boolean;
}

/**
 * Cleaned up BackendGetProjectItemsInvoicesRawResponse
 */
export interface BackendGetProjectItemsInvoicesResponseEntry
  extends Omit<
    BackendGetProjectItemsInvoicesRawResponseEntry,
    "files" | "draws"
  > {
  items: Record<
    TaskId,
    {
      amount: number;
    }
  >;
  storedFiles: InvoiceFile[];
}

/**
 * Backend wire response to /getprojectitems with item "Decisions"
 */
// Generated by https://quicktype.io
export interface BackendGetProjectItemsDecisionsRawResponseEntry {
  decision_id: string;
  project_id: string;
  task_id: string;
  task_name: string;
  description: string;
  created_at: string;
  approved_at: string;
  timeframe: string; // spell-checker:ignore timeframe
  files: string[];
  approval_status: ApprovalStatus;
  created_by: string;
  current_approver: string;
  final_approver: null;
  inspector: string | null;
  type: DecisionType | null;
}

/**
 * Cleaned up BackendGetProjectItemsDecisionsRawResponseEntry
 */
export interface BackendGetProjectItemsDecisionsResponseEntry
  extends Omit<BackendGetProjectItemsDecisionsRawResponseEntry, "files"> {
  storedFiles: StoredFile[];
}

export enum ProjectStatus {
  InProgress = "in-progress",
  Complete = "complete",
}

export const HasCompanyInputs = z.object({
  company: z.string(),
});
export const HasLicenseInputs = z.object({
  business_license: z.string().min(1),
});

export const PersonInputs = z.object({
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  email: z.string().email(),
  phone_number: z
    .string()
    .refine(
      (val) => /^[-+().0-9]+$/.test(val),
      (val) => ({
        message: `Invalid character(s) "${val.replace(/[-+().0-9]+/, "")}"`,
      })
    )
    .transform((s) => s.replace(/[+().-]/g, ""))
    // have to do it ourselves - https://github.com/colinhacks/zod#chaining-order
    .refine((s) => s.length >= 10, "Should have at least 10 digits")
    .refine(
      (s) => !isNaN(Number(s)),
      (s) => ({
        message: `!${s}! will not cut it today`,
      })
    ),
});

export const BusinessPersonInputs = PersonInputs.merge(HasCompanyInputs);
export const LicensedBusinessPersonInputs =
  BusinessPersonInputs.merge(HasLicenseInputs);

/* eslint-disable @typescript-eslint/no-redeclare */
export type BusinessPersonInputs = z.infer<typeof BusinessPersonInputs>;
export type LicensedBusinessPersonInputs = z.infer<
  typeof LicensedBusinessPersonInputs
>;
/* eslint-enable @typescript-eslint/no-redeclare */

export const AllPersonInputsSchema =
  PersonInputs.merge(HasLicenseInputs).merge(HasCompanyInputs);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AllPersonInputs = z.infer<typeof AllPersonInputsSchema> & {
  email: EmailAddress;
};

// TODO: doc like above, lather rinse repeat
export interface BackendRawGetTaskResponse {
  completed: boolean;
  created_at: string;
  critical: boolean;
  description: string | null;
  division: string;
  end_date: string | null;
  invoiced_amount: string | number;
  lender_only: boolean | null;
  project_id: string;
  requested_amount: string | number | null;
  start: string;
  subdivision: string;
  task_id: string;
  task_subcontractor: string | null;
  task_value: string | number;
  taskvacating: string | null;
  taskvacating_end: string | null;
  taskvacating_start: string | null;
  title: string;
  weekend: boolean;
}

export interface BackendGetTaskResponse
  extends Omit<
    BackendRawGetTaskResponse,
    "taskvacating" | "start" | "end_date"
  > {
  description: string;
  invoiced_amount: number;
  lender_only: boolean;
  requested_amount: number;
  task_subcontractor: string;
  task_value: number;
  task_full_value?: Money; // TODO: revisit this on retention branch
  taskvacating: boolean;
  taskvacating_end: string;
  taskvacating_start: string;
  title: string;
  weekend: boolean;
  start: Date;
  end_date: Date | null;
}

export interface BackendEditTaskInputs {
  title: NonEmptyString;
  task_value: number;
  description: string;
  start: Date;
  end_date?: Date | null;
  division: string;
  subdivision: string;
  critical: boolean;
  weekend: boolean;
  taskvacating: boolean;
  daysVacatingStartDate: Date;
  daysVacatingEndDate: Date;
  task_subcontractor: string; // object or JSON; comes back as JSON
  lender_only: boolean;
}
export interface BackendGetProjectTasksContractorEntry
  extends LicensedBusinessPersonInputs {
  projects: string[];
}

// TODO: this is not great
export type ContractorList = BackendGetProjectTasksContractorEntry[];
export interface BackendGetProjectTasksResponse {
  tasks: Task[];
  contractor: ContractorList;
}

export const CreateTaskInputs = z.object({
  title: z.string(),
  value: z.number().nonnegative(),
  description: z.string().optional(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  division: z.string().optional(),
  subdivision: z.string().optional(),
  taskvacating: z.boolean().optional(),
  daysVacatingStartDate: z.date().optional(),
  daysVacatingEndDate: z.date().optional(),
  critical: z.boolean().optional(),
  weekend: z.boolean().optional(),
  lenderOnly: z.boolean().optional(),
  task_subcontractor: z
    .union([LicensedBusinessPersonInputs, z.literal("")])
    .optional(),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CreateTaskInputs = z.infer<typeof CreateTaskInputs>;

/**
 * @deprecated Will be replaced with api-types
 */
export interface legacyBackendGetProjectDetailsRawResponse {
  project_id: string;
  name: string;
  users: string[];
  project_status: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  original_value: string;
  appraised_value: string;
  remaining_budget: string;
  syntheticBudget?: number;
  totalTaskValue?: number;
  totalActiveTaskValue?: number;
  budget_spent: string;
  img?: string;
  retention: string;
  max_draw_requests: string;
  tasks_outstanding: string[];
  tasks_complete: string[];
  all_tasks: string[];
  start_date: string;
  end_date: string;
  created_at: string;
  next_decision_date: null | string;
  project_type: string;
  original_square_feet: string;
  new_square_feet: string;
  square_feet: string;
  owner_occupied: string;
  files: {
    name: string;
    url: string;
  }[];
  health:
    | {
        severity: number;
        issue: {
          oneLine: string;
          title?: string;
          desc?: string;
        };
        color: "red" | "green" | "yellow";
      }[]
    | null;
  service_type: string;
  loan_amount: string | null;
  project_estimate_date: null;
  decision_routing: string[];
  invoice_routing: string[];
  draw_requests_remaining: number | null;
  loan_interest_rate: string | null;
  loan_start_date: string | null;
  loan_maturity_date: string | null;
  loan_type: string | null;
  loan_identifier: string | null;
  loan_contact: string[] | null;
  loan_borrower: string | null;
  loan_disbursed_at_closing: string | null;

  // loan_interest_reserve?: string | null;
  reporting: {
    type: "Interest Reserve" | "Invoice";
    enabled: boolean;
    statementReturnAddress: string; // TODO: markdown?
    interestReserve: number | null;
  } | null;
  summary?: {
    draws: {
      Approved: number;
      Rejected: number;
      Pending: number;
    };
    mri: {
      i: {
        timestamp: string; // creation
        approvalTimestamp: string;
        status: ApprovalStatus;
        currentApprover: EmailAddress;
        finalApprover: EmailAddress;
        isPut: boolean | null;
        delegate: string | null;
      };
      mrr: string;
    }[];
    p: {
      timestamp: string;
      approvalTimestamp: string | null;
      role: Role;
      currentApprover: EmailAddress;
      isPut: boolean | null;
      delegate: string | null;
    }[];
    pci?: { p: string; i: string; t: string }[]; // projects with project centerline concierge inspections pending
  };
  custom_1: string | null;
  property_type: WireProject["property_type"];
  units: WireProject["units"];
  location: WireProject["location"];
}

export type Location = Required<NonNullable<WireProject["location"]>>;

// right now these are the same, but they wouldn't always have to be
type BackendGetProjectsRawResponseEntry =
  legacyBackendGetProjectDetailsRawResponse;
export type BackendGetProjectsResponseEntry =
  legacyBackendGetProjectDetailsResponse;

/** Approval status for e.g. Invoices */
export enum ApprovalStatus {
  Pending = "Pending",
  Rejected = "Rejected",
  Approved = "Approved",
}

export const ApprovalStatusC = t.keyof({
  [ApprovalStatus.Approved]: null,
  [ApprovalStatus.Rejected]: null,
  [ApprovalStatus.Pending]: null,
});

type InferredApprovalStatusType = t.TypeOf<typeof ApprovalStatusC>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _ApprovalStatusSanity = MustSatisfy<
  ApprovalStatus,
  InferredApprovalStatusType
> &
  MustSatisfy<InferredApprovalStatusType, ApprovalStatus>;

export interface legacyBackendGetProjectDetailsResponse
  extends LoanModel,
    Omit<
      legacyBackendGetProjectDetailsRawResponse,
      | "remaining_budget" // string on wire, number inside
      | "original_value" // string on wire, number inside (originalValue)
      | "appraised_value" // string on wire, number inside (futureValue)
      | "original_square_feet" // string on wire, number inside (originalSquareFeet)
      | "new_square_feet" // string on wire, number inside (newSquareFeet)
      | "loan_amount" // string on wire, number inside (loanAmount)
      | "loan_type" // string, renamed to loanType inside
      | "retention" // string or null on wire, number (or null) inside
      | "max_draw_requests" // string on wire, number (named maxDraws) inside
      | "loan_interest_rate" // string on wire, number (or null, pre-reporting) inside
      | "loan_start_date" // string on wire, Date (or null, pre-reporting) inside
      | "loan_maturity_date" // string on wire, Date (or null, pre-reporting) inside
      | "loan_identifier" // inside loan object internally as loan.loanIdentifier??
      | "loan_contact" // inside loan object internally as loan.loanContact, as a string with embedded newlines instead of wire array
      | "loan_borrower" // inside loan object as loanBorrowerOverride
      | "loan_disbursed_at_closing" // string on wire, number (or null) inside
      | "actions"
      // | "loan_interest_reserve" // string on wire, number inside
      | "property_type" // propertyType inside
    > {
  // interestReserve: number | null;
  budgetRemaining: number; // remaining_budget, or totalTaskValue less budget_spent (quick create)
  totalTaskValue: number;
  totalActiveTaskValue: number;
  effectiveBudget: number; // loanAmount or totalTaskValue (quick create)
  retention: number | null;
  maxDraws: number | null;
  originalValue: number | null;
  futureValue: number | null;
  originalSquareFeet: number | null;
  newSquareFeet: number | null;
  propertyType: legacyBackendGetProjectDetailsRawResponse["property_type"];
  etag?: string;
}

/** Eventually this should just be Project in most places */
export type PartiallyModernizedGetProjectDetailsResponse = Omit<
  legacyBackendGetProjectDetailsResponse,
  "project_id"
> & {
  project_id: ProjectId;
};

////
const BackendGetProjectDetailsRawResponseC = t.intersection([
  t.type({
    project_id: t.string,
    name: t.string,
    users: t.array(EmailAddress),
    project_status: t.string,
    address: t.string,
    city: t.string,
    state: t.string,
    zipcode: t.string,
    original_value: t.string,
    appraised_value: t.string,
    remaining_budget: t.string,
    budget_spent: t.string,
    img: t.string,
    retention: t.string,
    max_draw_requests: t.string,
    tasks_outstanding: t.array(t.string),
    tasks_complete: t.array(t.string),
    all_tasks: t.array(t.string),
    start_date: t.string,
    end_date: t.string,
    created_at: t.string,
    next_decision_date: t.union([t.null, t.string]),
    project_type: t.string,
    original_square_feet: t.string,
    new_square_feet: t.string,
    square_feet: t.string,
    owner_occupied: t.string,
    files: t.array(t.type({ name: t.string, url: t.string })),
    health: tt.fromNullable(
      t.union([
        t.null,
        t.array(
          t.type({
            severity: t.number,
            issue: t.intersection([
              t.type({ oneLine: t.string }),
              t.partial({
                title: t.union([t.undefined, t.string]),
                desc: t.union([t.undefined, t.string]),
              }),
            ]),
            color: t.keyof({ red: null, green: null, yellow: null }),
          })
        ),
      ]),
      null
    ),
    service_type: t.string,
    loan_amount: t.union([t.null, t.string]),
    project_estimate_date: t.null,
    decision_routing: t.array(t.string),
    invoice_routing: t.array(t.string),
    draw_requests_remaining: nullable(t.number),
    loan_interest_rate: t.union([t.null, t.string]),
    loan_start_date: t.union([t.null, t.string]),
    loan_maturity_date: t.union([t.null, t.string]),
    loan_type: t.union([t.null, t.string]),
    loan_identifier: t.union([t.null, t.string]),
    loan_contact: t.union([t.null, t.array(t.string)]),
    loan_borrower: t.union([t.null, t.string]),
    loan_disbursed_at_closing: t.union([t.null, t.string]),
    reporting: t.union(
      [
        t.null,
        t.type(
          {
            enabled: t.literal(false),
          },
          "(disabled)"
        ),
        t.type(
          {
            type: t.keyof({ "Interest Reserve": null, Invoice: null }),
            enabled: t.boolean,
            statementReturnAddress: t.string,
            interestReserve: t.union([t.null, t.number]),
          },
          "(enabled)"
        ),
      ],
      "reporting"
    ),
    custom_1: t.union([t.null, t.string]),
    property_type: WireProject.types[0].props.property_type,
    units: WireProject.types[0].props.units,
    location: WireProject.types[1].props.location,
  }),
  t.partial({
    syntheticBudget: t.union([t.undefined, t.number]),
    totalTaskValue: t.union([t.undefined, t.number]),
    totalActiveTaskValue: t.union([t.undefined, t.number]),
    summary: t.union([
      t.undefined,
      t.intersection([
        t.type({
          draws: t.type({
            Approved: t.number,
            Rejected: t.number,
            Pending: t.number,
          }),
          mri: t.array(
            t.type({
              i: t.type({
                timestamp: t.string,
                approvalTimestamp: t.string,
                status: ApprovalStatusC,
                currentApprover: EmailAddress,
                finalApprover: EmailAddress,
                isPut: t.union([t.null, t.literal(false), t.literal(true)]),
                delegate: t.union([t.null, t.string]),
              }),
              mrr: t.string,
            })
          ),
          p: t.array(
            t.type({
              timestamp: t.string,
              approvalTimestamp: t.union([t.null, t.string]),
              role: RoleC,
              currentApprover: EmailAddress,
              isPut: t.union([t.null, t.literal(false), t.literal(true)]),
              delegate: t.union([t.null, t.string]),
            })
          ),
        }),
        t.partial({
          pci: t.union([
            t.undefined,
            t.array(t.type({ p: t.string, i: t.string, t: t.string })),
          ]),
        }),
      ]),
    ]),
  }),
]);

const {
  remaining_budget,
  original_value,
  appraised_value,
  original_square_feet,
  new_square_feet,
  loan_amount,
  loan_borrower,
  loan_contact,
  loan_disbursed_at_closing,
  loan_identifier,
  loan_interest_rate,
  loan_maturity_date,
  loan_start_date,
  loan_type,
  property_type,
  retention,
  max_draw_requests,
  ...keeperProps
} = BackendGetProjectDetailsRawResponseC.types[0].props;

export const RequiredProjectCProps = {
  ...keeperProps,
  propertyType: property_type,
};
export const OptionalProjectCProps = {
  budgetRemaining: t.number,
  totalTaskValue: t.number,
  totalActiveTaskValue: t.number,
  effectiveBudget: t.number,
  retention: t.union([t.null, t.number]),
  maxDraws: t.union([t.null, t.number]),
  originalValue: t.union([t.null, t.number]),
  futureValue: t.union([t.null, t.number]),
  originalSquareFeet: t.union([t.null, t.number]),
  newSquareFeet: t.union([t.null, t.number]),
};

/////
export const ProjectC = t.intersection(
  [
    t.type(RequiredProjectCProps, "(required)"),
    LoanModelC,
    t.type(OptionalProjectCProps, "(optional)"),
  ],
  "ProjectC"
);
export type Project = t.TypeOf<typeof ProjectC>;

/* eslint-disable @typescript-eslint/no-unused-vars */
type _sanity = MustSatisfy<
  Omit<legacyBackendGetProjectDetailsRawResponse, "reporting">,
  Omit<t.TypeOf<typeof BackendGetProjectDetailsRawResponseC>, "reporting">
>;
type _sanity2 = MustSatisfy<
  Omit<legacyBackendGetProjectDetailsResponse, "reporting">,
  Omit<Project, "reporting">
>;
type _sanity3 = MustSatisfy<legacyEmailAddress, EmailAddress>;
/* eslint-enable @typescript-eslint/no-unused-vars */

// export type UpdateProjectInputs = t.TypeOf<typeof PatchProjectBodySchema>;
export type UpdateProjectInputs = ProjectInfoFormOutputs;

export type BackendGetProjectsRawResponse =
  BackendGetProjectsRawResponseEntry[];
export type BackendGetProjectsResponse = BackendGetProjectsResponseEntry[];

export interface BackendUserResponse {
  projects: string[];
  // devices:          null;
  role: Role;
  first_name: string;
  last_name: string;
  email: EmailAddress;
  company: string;
  phone_number: string;
  // city:             null;
  // state:            null;
  // contractor_id:    null;
  // tax_id:           null;
  // division:         null;
  // subdivision:      null;
  created_at: string;
  // files:            null;
  // business_license: null;
  id: UserId;
  tags: ("external" | "one-click")[];
}

if (
  process.env.NODE_ENV !== "production" &&
  Date.now() > new Date(2024, 10, 27).getTime()
) {
  throw new Error(
    "Mitch's intentional time bomb went off to for migration to api-types"
  );
}

export const FileWithId = t.type({
  file_id: t.string,
});

/** A file known to the backend that can be viewed and such. StoredFile or LinkedFile, with backend properties */
export const PersistentProjectFile = t.intersection([
  t.union([StoredFile, LinkedFile]),
  ProjectFile,
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PersistentProjectFile = t.TypeOf<typeof PersistentProjectFile>;
export interface BackendGetProjectFilesResponse {
  userFiles: PersistentProjectFile[];
}

const sanitizeIndividualProject: (
  raw: legacyBackendGetProjectDetailsRawResponse
) => PartiallyModernizedGetProjectDetailsResponse = (raw) => {
  const {
    loan_amount,
    loan_type,
    original_value,
    appraised_value,
    original_square_feet,
    new_square_feet,
    remaining_budget,
    totalTaskValue,
    totalActiveTaskValue,
    syntheticBudget,
    retention,
    max_draw_requests,
    loan_interest_rate,
    loan_maturity_date,
    loan_start_date,
    loan_identifier: loanIdentifier,
    loan_contact,
    loan_borrower,
    loan_disbursed_at_closing,
    // loan_interest_reserve,
    reporting,
    property_type,
    ...rest
  } = raw;

  const effectiveBudget =
    loan_amount === ""
      ? (syntheticBudget !== undefined && !isNaN(syntheticBudget)
          ? syntheticBudget
          : totalTaskValue) ?? Number.NaN
      : Number(loan_amount);

  const budgetRemaining =
    remaining_budget === "" || loan_amount === "" // If there is no loan_amount, we can't trust remaining_budget either
      ? effectiveBudget - Number(raw.budget_spent)
      : Number(remaining_budget);

  const loanTypeIsRecognized = LoanTypeC.is(loan_type);
  if (loan_type !== null && !loanTypeIsRecognized) {
    console.warn(`Unrecognized loan type ${loan_type}`);
  }
  return {
    ...rest,
    project_id: rest.project_id as ProjectId,
    // interestReserve: loan_interest_reserve
    //   ? Number(loan_interest_reserve)
    //   : null,
    loanAmount:
      loan_amount === "" || loan_amount === null ? null : Number(loan_amount),
    loanType:
      ((loanTypeIsRecognized && loan_type) as LoanType) || LoanType.Term,
    effectiveBudget,
    totalTaskValue:
      totalTaskValue === undefined
        ? syntheticBudget ?? Number.NaN
        : totalTaskValue,
    totalActiveTaskValue:
      totalActiveTaskValue === undefined
        ? syntheticBudget ?? Number.NaN
        : totalActiveTaskValue,
    budgetRemaining,
    retention: retention ? Number(retention) : null,
    maxDraws: max_draw_requests ? Number(max_draw_requests) : null,
    interestRate: loan_interest_rate ? Number(loan_interest_rate) : null,
    maturityDate: loan_maturity_date ? new Date(loan_maturity_date) : null,
    startDate: loan_start_date ? new Date(loan_start_date) : null,
    loanIdentifier,
    loanContact: loan_contact?.join("\n") ?? null,
    loanBorrowerOverride: loan_borrower,
    disbursedAtClosing: loan_disbursed_at_closing
      ? Number(loan_disbursed_at_closing)
      : null,
    originalValue: original_value ? Number(original_value) : null,
    futureValue: appraised_value ? Number(appraised_value) : null,
    originalSquareFeet: original_square_feet
      ? Number(original_square_feet)
      : null,
    newSquareFeet: new_square_feet ? Number(new_square_feet) : null,

    reporting: (reporting || {
      enabled: false,
      type: "Interest Reserve",
      loanIdentifier: "",
    }) as LoanModel["reporting"],

    propertyType: property_type,
    img:
      raw.img ??
      "https://media.istockphoto.com/photos/blueprints-picture-id173620207?k=6&m=173620207&s=612x612&w=0&h=LuiJU5q9fP5A6B0wlFIQ0j7OhbAdCErRnXITXdJQ9CA=",
  };
};

const sanitizeGetProjectsResponse: (
  raw: BackendGetProjectsRawResponse
) => BackendGetProjectsResponse = (raw: BackendGetProjectsRawResponse) =>
  raw.map(sanitizeIndividualProject);

/**
 * Go through and deduplicate the various task lists. Dupes have been seen on staging, leading to
 * "14/9 tasks completed" which is embarrassing.
 *
 * TODO: should not need this forever
 *
 * @param {array<object>} projects project list from backend "getprojects"
 */
function deduplicateTasks(
  projects: BackendGetProjectsResponse
): BackendGetProjectsResponse {
  projects.forEach((project) => {
    ["all_tasks", "tasks_outstanding", "tasks_complete"].forEach((column) => {
      const taskColumnIndex = column as keyof BackendGetProjectsResponseEntry; // ok TS, you may be going a bit overboard
      const tasks = project[taskColumnIndex] as string[];
      const uniques = new Set([...tasks]);
      if (tasks.length !== uniques.size) {
        if (config.nonProdEnv) {
          const { project_id } = project;
          console.warn("duplicate tasks", {
            tasks,
            uniques,
            column,
            project_id,
          });
        }

        (project[taskColumnIndex] as string[]) = Array.from(uniques.values());
      }
    });
  });

  return projects;
}

export const notForGeneralUse = {
  sanitizeGetProjectsResponse,
  sanitizeIndividualProject,
  sanitizeGetUserRoleResponse,
  deduplicateTasks,
};
export interface BackendGetCommentsResponse {
  comment_id: string;
  decision_id: string | null;
  invoice_id: string | null;
  commenter_email: string;
  timestamp: string;
  message: string;
}

/***** */
// Generated by https://quicktype.io

export interface BackendGetSearchRawResult {
  tasks: BackendGetSearchRawTask[];
  draws: BackendGetSearchRawDraw[];
  decisions: BackendGetSearchRawDecision[];
  chats: BackendGetSearchRawChat[];
}

export interface BackendGetSearchRawChat {
  chat_id: string;
  chat_room_id: string;
  sender_name: string;
  sender_email: string;
  message: string;
  created_at: string;
  project_id: string;
  sender_company: string;
}

export interface BackendGetSearchRawDecision {
  decision_id: string;
  project_id: string;
  task_id: string;
  task_name: string;
  description: string;
  created_at: string;
  timeframe: string;
  files: string[]; // or is it StoredFile? TODO: test and learn, or study code
  approval_status: ApprovalStatus;
  approvers: null;
  created_by: string;
  current_approver: string;
  final_approver: null;
}

export interface BackendGetSearchRawDraw {
  invoice_id: string;
  project_id: string;
  draws: Array<string[]>;
  timestamp: string;
  approval_status: ApprovalStatus;
  current_approver: string;
  created_by: string;
  files: string[]; // or is it StoredFile? TODO: test and learn, or study code
  final_approver: string;
}

export interface BackendGetSearchRawTask {
  task_id: string;
  title: string;
  description: null;
  created_at: string;
  start: string;
  end_date: null;
  division: null;
  subdivision: null;
  taskvacating: null;
  critical: null;
  weekend: null;
  task_value: string;
  project_id: string;
  completed: boolean;
  invoiced_amount: string;
  requested_amount: null;
  taskvacating_start: null;
  taskvacating_end: null;
  task_subcontractor: null;
  lender_only: null;
}

export enum NotificationTypeEnum {
  Task = "Task",
  Decision = "Decision",
  DrawRequest = "Draw Request",
  Info = "Info",
  Chat = "Chat",
  Schedule = "Schedule",
}

/**
 * Backend wire response to "getuserrole"
 * // spell-checker:ignore getuserrole
 * Generated by https://quicktype.io
 */
interface BackendGetUserRoleRawResponse {
  role: { role: Role }[];
  notifications: Notification[];
  prepopulateLenderId?: UserId;
  id: UserId;
  version: string;
}

export interface Notification {
  notification_id: string;
  project_id: string;
  users: EmailAddress[];
  type: NotificationTypeEnum;
  message: string;
}

/**
 * Cleaned up `BackendGetUserRoleRawResponse. un-array's role
 */
export interface BackendGetUserRoleResponse
  extends Omit<BackendGetUserRoleRawResponse, "role"> {
  role: Role;
}

function sanitizeGetUserRoleResponse(
  raw: BackendGetUserRoleRawResponse
): BackendGetUserRoleResponse {
  const { role: roleArray, ...rest } = raw;
  return {
    ...rest,
    role: roleArray[0]?.role,
  };
}

export interface Inspector {
  email: EmailAddress;
  short: string;
  vendor: string;
  long: string;
  heading?: string;
  specialEmails?: boolean;
  flavor?: "virtual" | "digital" | "physical";
}
export interface BackendGetConfigResponse {
  inspectors: Inspector[];
  oneClickVendors: OneClickVendor[];
}

export interface BackendGetNVMSConfigResponse {
  services: {
    ServiceID: number;
    ServiceName: string;
    Price: number;
  }[];
}

export interface BackendGetStripeResponse {
  prices: {
    metadata: {
      pkey: string; // spell-checker:ignore pkey
    };
    amount: number;
    product: {
      name: string;
      description: string;
    };
  }[];
}

export interface OneClickVendor {
  prefix?: string;
  suffix: string;
  short: string;
  vendor: string;
  long: string;
}

export const DrawReportModeSchema = z.enum(["default", "emcap", "capstone"]);
export type DrawReportMode = z.infer<typeof DrawReportModeSchema>;

export const EntityFeaturesZodSchema = z.object({
  drawReportIncludePrevious: z.boolean().default(false),
  ccProjectCreatorOnNotifications: z.boolean().default(false),
  ccDecisionCreatorOnNotifications: z.boolean().default(false),
  ccInvoiceCreatorOnNotifications: z.boolean().default(false),
  perLineItemAttachments: z.boolean().default(false),
  automatedInspections: z.boolean().default(false),
  digitalInspections: z.boolean().default(false),
  restrictInspectors: z.boolean().default(false),
  paidInvoiceCheckbox: z.boolean().default(false),
  drawReportMode: DrawReportModeSchema.default(
    DrawReportModeSchema.enum.default
  ),
  frontend: z
    .object({
      drawDocsRequired: z
        .object({
          creation: z.number().int().nonnegative().default(0),
          approval: z.number().int().nonnegative().default(0),
        })
        .default({}),
      subtractCompletedTasksFromHoldback: z.boolean().default(false),
      reportingTab: z.boolean().default(false),
      hideDecisions: z.boolean().default(true),
      // newUI: z.boolean().default(false),
      onDemandInspections: z
        .preprocess(
          (wire: unknown) => (Array.isArray(wire) ? new Set(wire) : wire),
          z.set(z.nativeEnum(Role))
        )
        .default([]),
      showRetention: z.boolean().default(false),
      newUI: z.enum(["on", "off", "offer", "no-offer"]).default("no-offer"),
    })
    .default({}),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EntityFeatures = z.infer<typeof EntityFeaturesZodSchema>;

export interface BackendGetEntityListResponseEntry {
  id: number;
  matchDomains: string[] | null;
  matchEmails: User["email"][] | null;
  autoAddUserIdentities: User["identity"][] | null;
  autoAddUserEmails: User["email"][] | null;
  overrideInvoiceRouting: User["email"][] | null;
  defaultLoanContact: string[] | null;
  prepopulateLenderId: string | null;
  features: EntityFeatures | null;
  ccAllNotifications: string[];
  logo: string | null;
  nvms?: {
    apiSecret: string;
    twoDayInspectionService: number;
    nvmsUserId: number;
    payments: {
      sfr:
        | {
            type: "stripe";
            priceKey: string;
          }
        | {
            type: "john";
            amount: number;
          }
        | {
            type: "not-required";
          };
    };
    sendAllItems?: boolean;
  } | null;
}

export type Entity = BackendGetEntityListResponseEntry & {
  etag?: string;
};
export type BackendGetEntityListResponse = {
  entity: BackendGetEntityListResponseEntry;
  ETag: string;
}[];

// Generated by https://quicktype.io

export interface BackendCognitoUserInfo {
  user: CognitoUser;
}

interface CognitoUser {
  Username: string;
  Attributes: Attribute[];
  UserCreateDate: Date;
  UserLastModifiedDate: Date;
  Enabled: boolean;
  UserStatus: string;
}

interface Attribute {
  Name: string;
  Value: string;
}

export interface BackendGetUserDetailsResponse {
  role: Role;
  email: EmailAddress;
  company: string | null;
  phone: string | null;
  city: string | null;
  state: string | null;
  identity: string | null;
  id: UserId;
  firstName: string;
  lastName: string;
  projects: ProjectId[];
  created_at: Date | null;
  // for super admins
  cognito?:
    | BackendCognitoUserInfo
    | /* backend has been known to return empty object */ Record<string, never>;
}

// eventually want to get rid of these Omits
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _UserSanity = MustSatisfy<
  Omit<BackendGetUserDetailsResponse, "role" | "cognito">,
  User
> &
  MustSatisfy<Omit<User, "role" | "cognito">, BackendGetUserDetailsResponse>;

// too lazy to figure out how to deal with `| Record<string, never>` so we omitted, now check it separately
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _CogSanity = MustSatisfy<
  BackendCognitoUserInfo | undefined,
  User["cognito"]
> &
  MustSatisfy<User["cognito"], BackendCognitoUserInfo>;
/** results from /inspection/{id} */
export interface BackendGetInspectionResponse extends BackendInspectionCommon {
  vendorLink?: string;
  completedAt?: Date;
  accepted?: boolean;
  expectedAt?: Date;
  status?: "waiting-dispatch" | "waiting-assigned" | "complete" | "unknown";
}
