import { matchPath, useLocation } from "react-router-dom";

import TimSidenav, { RouteEntry } from "@tim/examples/Sidenav"; // spell-checker:word sidenav
import { CrumbFunction } from "@tim/examples/Breadcrumbs";

import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import {
  CheckBoxOutlined,
  FolderOutlined,
  LocationOnOutlined,
  Search,
} from "@mui/icons-material";

export interface HandleStuff {
  tag?: string;
  desc?: string;
  crumb?: CrumbFunction;
}

type CreativeTimRouteEntry = RouteEntry & HandleStuff;

// factories that help coerce string types etc
const timRouteEntry = (o: CreativeTimRouteEntry) => o;
const timCollapseEntry = (o: Omit<CreativeTimRouteEntry, "collapse">) => o;

const projectRoutes = (pathname: string) => {
  const {
    params: { projectId = undefined } = {},
    pathnameBase: projectPrefix,
  } = matchPath("/projectview/:projectId/*", pathname) ?? {};
  const drawsPrefix = projectId && `/projectview/${projectId}/draws`;
  return projectId
    ? [
        timRouteEntry({
          type: "divider",
          key: "divider-projects",
          name: "divider-projects",
        }),
        timRouteEntry({
          type: "title",
          key: "Project",
          name: "Project",
          title: "Project",
        }),
        timRouteEntry({
          key: "PROJECT",
          name: "PROJECT",
          route: projectPrefix,
          type: "collapse",
          styleAsTitle: "open",
          collapse: [
            timCollapseEntry({
              name: "Activity",
              route: `${projectPrefix}/activity`,
              key: "Activity",
            }),
          ],
        }),
        timRouteEntry({
          key: "draws",
          name: "DRAWS",
          route: drawsPrefix,
          type: "collapse",
          noCollapse: true,
          icon: <MonetizationOnOutlinedIcon fontSize="medium" />,
          collapse: [
            timCollapseEntry({
              name: "New",
              route: `${drawsPrefix}/new`,
              key: "new-draw",
            }),
            timCollapseEntry({
              name: "Pending",
              route: `${drawsPrefix}/pending`,
              key: "pending-draws",
            }),
            timCollapseEntry({
              name: "Approved",
              route: `${drawsPrefix}/approved`,
              key: "approved-draws",
            }),
            timCollapseEntry({
              name: "Rejected",
              route: `${drawsPrefix}/rejected`,
              key: "rejected-draws",
            }),
          ],
        }),
        // More Routes Page, went ahead and attached to existing routes with new tab and dropdown
        timRouteEntry({
          key: "more",
          name: "MORE",
          route: projectPrefix,
          type: "collapse",

          collapse: [
            timCollapseEntry({
              name: "Budget",
              key: "Budget",
              route: `${projectPrefix}/budget`,
            }),
            timCollapseEntry({
              key: "routing",
              name: "Default Routing",
              route: `${drawsPrefix}/routing`,
            }),
            timCollapseEntry({
              name: "Files",
              key: "Files",
              route: `${projectPrefix}/files`,
            }),
            timCollapseEntry({
              name: "Lease Info",
              route: `${projectPrefix}/lease`,
              key: "leaseInfo",
            }),
            timCollapseEntry({
              name: "Loan Info",
              key: "LoanInfo",
              route: `${projectPrefix}/loan`,
            }),
            timCollapseEntry({
              name: "Property Info",
              key: "PropertyInfo",
              route: `${projectPrefix}/property`,
            }),
            timCollapseEntry({
              name: "Reporting",
              key: "Reporting",
              route: `${projectPrefix}/reporting`,
            }),
            timCollapseEntry({
              name: "Turnover Info",
              key: "Turnover",
              route: `${projectPrefix}/Turnover`,
            }),
            timCollapseEntry({
              name: "Users",
              key: "Users",
              route: `${projectPrefix}/users`,
            }),
          ],
        }),
      ]
    : ([
        /* no ProjectId */
      ] as CreativeTimRouteEntry[]);
};

/**
 * Generate creative tim "routes" for sidenav
 *
 * Here are the basic rules:
 *
 * 1. There are only two levels of nesting in tim
 * 2. "collapse" does children, and makes a twirly
 * 2a. Unless, that is, you set "noCollapse:"" :-)
 * 3. `route` is used to match, a la react-router
 * 4. (probably more; document here as you learn)
 *
 * @param pathname current location
 * @returns an array of creative tim "route" objects to build the sidenav from
 */
const routes: (pathname: string) => Array<CreativeTimRouteEntry> = (
  pathname
) => {
  const isProfile = matchPath("/profile", pathname);
  const routes = (
    isProfile
      ? [
          timRouteEntry({
            key: "profile",
            name: "Profile",
            route: "/profile",
            title: "User Profile",
            type: "collapse",
            noCollapse: true,
          }),
        ]
      : []
  )
    .concat([
      timRouteEntry({
        type: "collapse",
        name: "CREATE",
        key: "create",
        title: "Create",
        route: "new",
        collapse: [
          timCollapseEntry({
            name: "Project",
            key: "create-project",
            route: "/new/project",
            icon: <FolderOutlined fontSize="large" color="white" />,
          }),
          timCollapseEntry({
            name: "Inspection",
            key: "create-inspection",
            route: "/new/inspection",
            icon: <Search fontSize="large" color="white" />,
            type: "collapse",
            noCollapse: true,
          }),
          timCollapseEntry({
            name: "Check In",
            key: "create-check-in",
            route: "/new/checkin", // spell-checker:ignore checkin
            type: "collapse",
            noCollapse: true,
            icon: <LocationOnOutlined fontSize="large" color="white" />,
          }),
          timCollapseEntry({
            name: "Budget Review",
            key: "create-Budget-Review",
            route: "/new/budget-review",
            type: "collapse",
            noCollapse: true,
            icon: <CheckBoxOutlined fontSize="large" color="white" />,
          }),
        ] as Array<CreativeTimRouteEntry>,
      }),
      timRouteEntry({
        type: "collapse",
        name: "PORTFOLIO",
        key: "portfolio",
        title: "Portfolio",
        route: "portfolio", //Leaving as new for right now until we have an idea on how we want to display Construction and Leasing Pages/Routes
        collapse: [
          timCollapseEntry({
            name: "Dashboard",
            key: "Dashboard",
            route: "/portfolio/todo", // spell-checker:ignore checkin
            type: "collapse",

            noCollapse: true,
          }),
          timCollapseEntry({
            name: "Activity",
            key: "portfolio-Activity",
            route: "/portfolio/todo",
            type: "collapse",
            noCollapse: true,
          }),
        ] as Array<CreativeTimRouteEntry>,
      }),
    ])
    .concat(projectRoutes(pathname));
  return routes;
};

export const Sidenav = (
  props: Omit<Parameters<typeof TimSidenav>[0], "routes">
) => {
  const { pathname } = useLocation();
  return <TimSidenav {...props} routes={routes(pathname)} />;
};
