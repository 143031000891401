import { throwIfNot } from "@/Lib/util/throwIfNot";
import { useParams } from "react-router-dom";
import { useProjectDetails } from "@/Lib/hooks/useProjectDetails";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem, { Props } from "examples/Timeline/TimelineItem";

const ActivityPage = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  );

  const { project } = useProjectDetails(projectId); // TODO: How to handle required react router params. e.g. https://github.com/remix-run/react-router/issues/8498
  type TimelineProps = Props & {
    icon: string;
  };
  const COLORS: Record<Props["title"], Props["color"]> = {
    "Check In": "success", // green
    "Check Out": "dark", // dark blue
    Cancelled: "error", // red
  } as const;

  const ICONS: Record<Props["title"], Props["icon"]> = {
    "Check In": "done", //Checkmark
    "Check Out": "done", //Checkmark
    Cancelled: "cancel", // X with Circle
  } as const;
  const data: Array<TimelineProps> = [
    {
      color: COLORS["checkIn"],
      icon: "done",
      title: "Check In",
      dateTime: "22 DEC 7:20 PM",
      description: "Framing installed correctly and ready for roofing",
    },
    {
      color: COLORS["checkOut"],
      icon: "done",
      title: "Check Out",
      dateTime: "15 DEC 7:20 PM",
      description: "Could not install concrete, was too wet and got sent back",
    },
    {
      color: COLORS["Cancelled"],
      icon: "done",
      title: "Cancelled",
      dateTime: "1 DEC 7:20 PM",
      description: "Plans got sent off and were finally approved today :)",
    },
    {
      color: COLORS["checkIn"],
      icon: "done",
      title: "Check In",
      dateTime: "30 NOV 7:20 PM",
      description: "Need to Contact the city for approval",
    },
    {
      color: "error",
      icon: "cancel",
      title: "Cancelled",
      dateTime: "15 DEC 7:20 PM",
      description: "Could not install concrete, was too wet and got sent back",
    },
    {
      color: COLORS["checkIn"],
      icon: "done",
      title: "Check In",
      dateTime: "1 DEC 7:20 PM",
      description: "Plans got sent off and were finally approved today :)",
    },
    {
      color: COLORS["checkIn"],
      icon: "done",
      title: "Check In",
      dateTime: "1 DEC 7:20 PM",
      description: "Plans got sent off and were finally approved today :)",
    },
    {
      color: "secondary",
      icon: "done",
      title: "Check Out",
      dateTime: "30 NOV 7:20 PM",
      description: "Need to Contact the city for approval",
    },
  ];
  return (
    <div style={{ width: "81vw", height: "50vh", flexDirection: "row" }}>
      <TimelineList title={project?.details?.name ?? ""}>
        <>
          {data.map((item) => {
            return (
              <TimelineItem
                color={COLORS[item.title]}
                icon={ICONS[item.title]}
                title={item.title}
                dateTime={item.dateTime}
                description={item.description}
                // badges={["server", "payments"]} optional, not sure how to use exactly
              />
            );
          })}
        </>
      </TimelineList>
    </div>
  );
};

export default ActivityPage;
